<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { HeadlessDialogPanel } from "#components";

const emit = defineEmits<{
  "update:isOpen": [value: boolean];
}>();

const props = withDefaults(
  defineProps<{
    isOpen?: boolean;
    title?: string;
    class?: HTMLAttributes["class"];

    tonal?: boolean;
    wide?: boolean;
    front?: boolean;
  }>(),
  {}
);

const isOpen = useVModel(props, "isOpen", emit, {
  passive: true,
  defaultValue: false,
});

const { currentPalette } = useTheme();
const { isMobile } = useUserDevice();
</script>

<template>
  <Teleport to="body">
    <HeadlessTransitionRoot :show="isOpen" as="template">
      <HeadlessDialog
        class="app-dialog relative text-sm pt-[4.125rem] lg:pt-0"
        :class="[front ? 'z-50' : 'z-40', props.class]"
        @close="isOpen = false"
        v-bind="$attrs"
        static
      >
        <HeadlessTransitionChild as="template">
          <div
            class="app-dialog__overlay fixed inset-0 lg:backdrop-blur-sm cursor-pointer bg-white"
            aria-hidden="true"
          >
            <div
              class="fixed bottom-0 w-screen h-[50vh]"
              :class="[currentPalette.bg]"
            ></div>
          </div>
        </HeadlessTransitionChild>

        <HeadlessTransitionChild as="template">
          <component
            :is="isMobile ? HeadlessDialogPanel : 'div'"
            class="fixed inset-0 w-screen overflow-y-auto overflow-x-hidden overscroll-none"
          >
            <div
              class="app-dialog__header bg-white sticky top-0 z-10 lg:hidden h-[4.125rem] flex flex-col items-stretch justify-center select-none"
            >
              <div class="app-dialog__header-container container">
                <div
                  class="app-dialog__header-wrapper flex items-center justify-between gap-4"
                >
                  <div
                    class="app-dialog__header-section app-dialog__header-section--start flex items-center gap-4"
                  >
                    <AppHeaderLogo @click.native="isOpen = false" />
                  </div>

                  <div
                    class="app-dialog__header-section app-dialog__header-section--end flex items-center -m-3"
                  >
                    <slot name="header-actions"></slot>

                    <button
                      class="app-dialog__close app-dialog__close--mobile w-12 h-12 flex items-center justify-center"
                      title="Zamknij"
                      @click="isOpen = false"
                    >
                      <div
                        class="w-6 h-6 flex flex-col items-end justify-between"
                      >
                        <HeadlessTransitionChild
                          as="template"
                          enter="transition-transform duration-300"
                          enter-from="rotate-0"
                          enter-to="-rotate-45 translate-y-[0.71875rem]"
                          leave="transition-transform duration-300"
                          leave-from="-rotate-45 translate-y-[0.71875rem]"
                          leave-to="rotate-0"
                          entered="-rotate-45 translate-y-[0.71875rem]"
                        >
                          <div class="bg-black w-6 h-[0.0625rem]"></div>
                        </HeadlessTransitionChild>
                        <HeadlessTransitionChild
                          as="template"
                          enter="transition-opacity duration-300"
                          enter-from="opacity-100"
                          enter-to="opacity-0"
                          leave="transition-opacity duration-300"
                          leave-from="opaciy-0"
                          leave-to="opacity-100"
                          entered="opacity-0"
                        >
                          <div
                            class="bg-black w-[0.9375rem] h-[0.0625rem]"
                          ></div>
                        </HeadlessTransitionChild>
                        <HeadlessTransitionChild
                          as="template"
                          enter="transition-transform duration-300"
                          enter-from="rotate-0"
                          enter-to="rotate-45 -translate-y-[0.71875rem]"
                          leave="transition-transform duration-300"
                          leave-from="rotate-45 -translate-y-[0.71875rem]"
                          leave-to="rotate-0"
                          entered="rotate-45 -translate-y-[0.71875rem]"
                        >
                          <div class="bg-black w-6 h-[0.0625rem]"></div>
                        </HeadlessTransitionChild>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <HeadlessTransitionChild
              as="template"
              enter="transition-transform duration-300 ease-out"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transition-transform duration-300 ease-in"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <div
                class="flex flex-col min-h-[calc(100dvh_-_4.125rem)] lg:min-h-full w-full lg:max-w-[72.5rem] mx-auto items-center justify-center lg:py-8 transform"
              >
                <component
                  :is="isMobile ? 'div' : HeadlessDialogPanel"
                  class="app-dialog__panel relative w-full min-h-[calc(100dvh_-_4.125rem)] lg:min-h-[45rem] flex flex-col items-stretch justify-stretch overflow-hidden lg:rounded-b-[2.5rem] lg:rounded-s-[2.5rem]"
                  :class="[props.tonal ? currentPalette.bg : 'bg-white']"
                >
                  <button
                    class="app-dialog__close app-dialog__close--desktop absolute top-0 end-0 w-16 h-16 hidden lg:flex items-center justify-center"
                    title="Zamknij"
                    @click="isOpen = false"
                  >
                    <Icon name="ns:close-big" />
                  </button>

                  <div
                    class="app-dialog__panel-wrapper grow h-full flex items-stretch justify-stretch"
                  >
                    <div
                      v-if="$slots.side"
                      class="app-dialog__side basis-[45rem] w-[45rem] relative bg-black/5 hidden lg:block"
                    >
                      <slot name="side"></slot>
                    </div>

                    <div
                      class="app-dialog__content lg:w-[27.5rem] grow flex flex-col items-stretch justify-stretch"
                    >
                      <div
                        class="app-dialog__subheader"
                        v-if="$slots['subheader'] && isMobile"
                      >
                        <div
                          class="app-dialog__subheader-container lg:px-20 h-14 flex flex-col items-stretch justify-center"
                          :class="[props.wide ? 'px-5' : 'px-12']"
                        >
                          <div
                            class="app-dialog__subheader-wrapper flex items-center justify-stretch gap-4"
                          >
                            <slot name="subheader"></slot>
                          </div>
                        </div>
                      </div>

                      <div
                        class="app-dialog__container py-5 pb-10 lg:py-[3.75rem] grow lg:px-20 flex flex-col items-stretch justify-stretch"
                        :class="[props.wide ? 'px-5' : 'px-12']"
                      >
                        <HeadlessDialogTitle
                          v-if="props.title"
                          as="h2"
                          class="text-2xl font-bold mb-1"
                        >
                          {{ props.title }}
                        </HeadlessDialogTitle>

                        <HeadlessDialogDescription as="div" class="grow">
                          <slot></slot>
                        </HeadlessDialogDescription>
                      </div>
                    </div>
                  </div>
                </component>
              </div>
            </HeadlessTransitionChild>
          </component>
        </HeadlessTransitionChild>
      </HeadlessDialog>
    </HeadlessTransitionRoot>
  </Teleport>
</template>
