<script setup lang="ts">
import { AppHeaderActionsItem, NuxtLink } from "#components";
import { AuthView } from "#imports";

const emit = defineEmits<{ "update:isOpen": [value: boolean] }>();

const props = withDefaults(defineProps<{ isOpen?: boolean }>(), {});

const isOpen = useVModel(props, "isOpen", emit, {
  passive: true,
  defaultValue: false,
});

const { openAuthDialog, isLoggedIn, user, logoutUser } = useAuth();
const { isMobile } = useUserDevice();

const { menuItems } = useMenu();
const { socialMenu } = useFooter();

const localePath = useLocalePath();
</script>

<template>
  <AppMenuDialog v-if="isMobile" v-model:isOpen="isOpen" wide tonal>
    <template #header-actions>
      <template v-if="isLoggedIn">
        <AppHeaderActionsItem
          :tag="NuxtLink"
          :to="localePath({ name: 'boards' })"
          @click.native="isOpen = false"
        >
          <Icon name="ns:heart" />
        </AppHeaderActionsItem>

        <AppHeaderActionsItem
          :tag="NuxtLink"
          :to="localePath({ name: 'account-settings' })"
          @click.native="isOpen = false"
        >
          <AppAvatar v-if="user" :user="user" size="sm" />
          <Icon v-else name="ns:user-circle-2" />
        </AppHeaderActionsItem>
      </template>
    </template>

    <template #default>
      <div class="h-full flex flex-col items-stretch justify-start gap-10">
        <div class="grow flex flex-col items-end justify-start gap-10">
          <NuxtLink
            v-for="item in menuItems"
            :key="item.key"
            :to="item.to"
            class="inline-flex items-center justify-center gap-2 py-2 px-4 text-2xl leading-5 font-display"
            @click.native="isOpen = false"
          >
            <span>{{ item.label }}</span>
          </NuxtLink>

          <AppBtn
            v-if="isLoggedIn"
            @click="
              () => {
                isOpen = false;
                logoutUser();
              }
            "
          >
            <span>wyloguj</span>
          </AppBtn>

          <AppBtn
            v-else
            @click="
              () => {
                isOpen = false;
                openAuthDialog(AuthView.Login);
              }
            "
          >
            <span>zaloguj</span>
          </AppBtn>
        </div>

        <div class="flex flex-col items-end justify-start gap-10">
          <NuxtLink
            :to="localePath({ name: 'contact' })"
            class="inline-flex items-center justify-center gap-2 py-2 px-4 text-2xl leading-5 font-display"
            @click.native="isOpen = false"
          >
            <span>kontakt</span>
          </NuxtLink>

          <ul class="flex flex-row items-end lg:items-center gap-4">
            <li v-for="item in socialMenu">
              <AppFooterSocialItem v-bind="{ item }" />
            </li>
          </ul>
        </div>
      </div>
    </template>
  </AppMenuDialog>
</template>
